import lodashGet from 'lodash.get';
import { isLTTUStaff, isSGDStaff } from 'components/admin/user/utils';
import lodashIntersection from 'lodash.intersection';
import {
  TRAINING_MODEL,
  TRAINING_MODEL_INT_VAL,
} from 'components/taphuan/configs';
import { specialTpTypes } from 'configs/constants/training-plan';
import { head, last } from 'common/utils/Array';
import { BGD_ORG_IID } from 'components/admin/organization/utils';

export const havePlannedUsersTrainingPlan = (trainingPlan) => {
  return lodashGet(trainingPlan, 'have_planned_users');
};

export const haveQuotaForPlannedUsersTrainingPlan = (trainingPlan) => {
  return lodashGet(trainingPlan, 'have_quota_for_planned_users');
};

export const bdtxPlannedUserCanOnlyLearnPlannedAcademicCategories = (
  trainingPlan,
) => {
  return lodashGet(
    trainingPlan,
    'bdtx_planned_user_can_only_learn_planned_academic_categories',
  );
};

export const etepTps = {
  TP_IID_GVCC_MODULE_1: 4011669,
  TP_IID_CBQLCC_MODULE_1: 4222201,
  TP_IID_IVA: 47279940,
  TP_IID_IVA_CC_MODULE_5: 80506759,

  TP_IID_GVCC_MODULE_1_BO_SUNG: 24705719,
  TP_IID_CBQLCC_MODULE_1_BO_SUNG: 24705730,

  TP_IID_GVCC_MODULE_2: 24556398,
  TP_IID_CBQLCC_MODULE_2: 24556402,

  TP_IID_GVCC_MODULE_3: 24825441,
  TP_IID_CBQLCC_MODULE_3: 24866803,

  TP_IID_GVCC_MODULE_4: 68173268,
  TP_IID_CBQLCC_MODULE_4: 68180259,

  TP_IID_GVCC_MODULE_5: 81885718,
  TP_IID_CBQLCC_MODULE_5: 81885798,

  TP_IID_GVCC_MODULE_9: 86147674,
  TP_IID_CBQLCC_MODULE_9: 86148206,

  TP_IID_RGEP_MODULE_1: 4032816,
  TP_IID_RGEP_MODULE_2: 29633627,
  TP_IID_RGEP_MODULE_3: null,

  TP_IID_HOI_THAO_MODULE_4: 62667213,
};

// see TrainingPlan_Entity_TrainingPlanCategory
export const trainingPlanModules = {
  MODULE_1_COTCAN: 1,
  MODULE_1_DAITRA: 2,
  MODULE_2_COTCAN: 3,
  MODULE_2_DAITRA: 4,
  MODULE_3_COTCAN: 5,
  MODULE_3_DAITRA: 6,
  MODULE_4_COTCAN: 7,
  MODULE_4_DAITRA: 8,
  MODULE_5_COTCAN: 9,
  MODULE_5_DAITRA: 10,
  MODULE_9_COTCAN: 11,
  MODULE_9_DAITRA: 12,
  MODULE_6_COTCAN: 13,
  MODULE_6_DAITRA: 14,
  MODULE_7_COTCAN: 15,
  MODULE_7_DAITRA: 16,
  MODULE_8_COTCAN: 17,
  MODULE_8_DAITRA: 18,
  MODULE_DEMO: 30,
  MODULE_RGEP: 31,
  MODULE_KHAC: 32,
};

export const TAPHUAN_TRAINING_PLAN_CATEGORY_NAMES = {
  [trainingPlanModules.MODULE_1_COTCAN]: 'Mô đun 1 Cốt cán',
  [trainingPlanModules.MODULE_1_DAITRA]: 'Mô đun 1 Đại trà',
  [trainingPlanModules.MODULE_2_COTCAN]: 'Mô đun 2 Cốt cán',
  [trainingPlanModules.MODULE_2_DAITRA]: 'Mô đun 2 Đại trà',
  [trainingPlanModules.MODULE_3_COTCAN]: 'Mô đun 3 Cốt cán',
  [trainingPlanModules.MODULE_3_DAITRA]: 'Mô đun 3 Đại trà',
  [trainingPlanModules.MODULE_4_COTCAN]: 'Mô đun 4 Cốt cán',
  [trainingPlanModules.MODULE_4_DAITRA]: 'Mô đun 4 Đại trà',
  [trainingPlanModules.MODULE_5_COTCAN]: 'Mô đun 5 Cốt cán',
  [trainingPlanModules.MODULE_5_DAITRA]: 'Mô đun 5 Đại trà',
  [trainingPlanModules.MODULE_9_COTCAN]: 'Mô đun 9 Cốt cán',
  [trainingPlanModules.MODULE_9_DAITRA]: 'Mô đun 9 Đại trà',
  [trainingPlanModules.MODULE_6_COTCAN]: 'Mô đun 6 Cốt cán',
  [trainingPlanModules.MODULE_6_DAITRA]: 'Mô đun 6 Đại trà',
  [trainingPlanModules.MODULE_7_COTCAN]: 'Mô đun 7 Cốt cán',
  [trainingPlanModules.MODULE_7_DAITRA]: 'Mô đun 7 Đại trà',
  [trainingPlanModules.MODULE_8_COTCAN]: 'Mô đun 8 Cốt cán',
  [trainingPlanModules.MODULE_8_DAITRA]: 'Mô đun 8 Đại trà',
  //        '4' : 'Module 2 Đại trà',
  //        '3' : 'Module 3',
  //        '4' : 'Module 4',
  //        '5' : 'Module 5',
  //        '6' : 'Module 6',
  //        '7' : 'Module 7',
  //        '8' : 'Module 8',
  //        '9' : 'Module 9',
  [trainingPlanModules.MODULE_DEMO]: 'Mô đun Demo',
  [trainingPlanModules.MODULE_RGEP]: 'RGEP',
  [trainingPlanModules.BDTX]: 'BDTX (Bồi dưỡng thường xuyên hàng năm)',
  [trainingPlanModules.MODULE_KHAC]: 'Khác',
};

export const TAPHUAN_TRAINING_PLAN_CATEGORY_NAMES_SIMPLE = {
  [trainingPlanModules.MODULE_1_COTCAN]: 'Mô đun 1',
  [trainingPlanModules.MODULE_1_DAITRA]: 'Mô đun 1',
  [trainingPlanModules.MODULE_2_COTCAN]: 'Mô đun 2',
  [trainingPlanModules.MODULE_2_DAITRA]: 'Mô đun 2',
  [trainingPlanModules.MODULE_3_COTCAN]: 'Mô đun 3',
  [trainingPlanModules.MODULE_3_DAITRA]: 'Mô đun 3',
  [trainingPlanModules.MODULE_4_COTCAN]: 'Mô đun 4',
  [trainingPlanModules.MODULE_4_DAITRA]: 'Mô đun 4',
  [trainingPlanModules.MODULE_5_COTCAN]: 'Mô đun 5',
  [trainingPlanModules.MODULE_5_DAITRA]: 'Mô đun 5',
  [trainingPlanModules.MODULE_9_COTCAN]: 'Mô đun 9',
  [trainingPlanModules.MODULE_9_DAITRA]: 'Mô đun 9',
  [trainingPlanModules.MODULE_6_COTCAN]: 'Mô đun 6',
  [trainingPlanModules.MODULE_6_DAITRA]: 'Mô đun 6',
  [trainingPlanModules.MODULE_7_COTCAN]: 'Mô đun 7',
  [trainingPlanModules.MODULE_7_DAITRA]: 'Mô đun 7',
  [trainingPlanModules.MODULE_8_COTCAN]: 'Mô đun 8',
  [trainingPlanModules.MODULE_8_DAITRA]: 'Mô đun 8',
};

export const TAPHUAN_COTCAN_TRAINING_PLAN_CATEGORIES_IN_ORDER = [
  trainingPlanModules.MODULE_1_COTCAN,
  trainingPlanModules.MODULE_2_COTCAN,
  trainingPlanModules.MODULE_3_COTCAN,
  trainingPlanModules.MODULE_4_COTCAN,
  trainingPlanModules.MODULE_5_COTCAN,
  trainingPlanModules.MODULE_9_COTCAN,
  trainingPlanModules.MODULE_6_COTCAN,
  trainingPlanModules.MODULE_7_COTCAN,
  trainingPlanModules.MODULE_8_COTCAN,
];

export const TAPHUAN_DAITRA_TRAINING_PLAN_CATEGORIES_IN_ORDER = [
  trainingPlanModules.MODULE_1_DAITRA,
  trainingPlanModules.MODULE_2_DAITRA,
  trainingPlanModules.MODULE_3_DAITRA,
  trainingPlanModules.MODULE_4_DAITRA,
  trainingPlanModules.MODULE_5_DAITRA,
  trainingPlanModules.MODULE_9_DAITRA,
  trainingPlanModules.MODULE_6_DAITRA,
  trainingPlanModules.MODULE_7_DAITRA,
  trainingPlanModules.MODULE_8_DAITRA,
];

export const TP_CATEGORY_BY_MODULE_INDEX = {
  1: [trainingPlanModules.MODULE_1_COTCAN, trainingPlanModules.MODULE_1_DAITRA],
  2: [trainingPlanModules.MODULE_2_COTCAN, trainingPlanModules.MODULE_2_DAITRA],
  3: [trainingPlanModules.MODULE_3_COTCAN, trainingPlanModules.MODULE_3_DAITRA],
  4: [trainingPlanModules.MODULE_4_COTCAN, trainingPlanModules.MODULE_4_DAITRA],
  5: [trainingPlanModules.MODULE_5_COTCAN, trainingPlanModules.MODULE_5_DAITRA],
  9: [trainingPlanModules.MODULE_9_COTCAN, trainingPlanModules.MODULE_9_DAITRA],
  6: [trainingPlanModules.MODULE_6_COTCAN, trainingPlanModules.MODULE_6_DAITRA],
  7: [trainingPlanModules.MODULE_7_COTCAN, trainingPlanModules.MODULE_7_DAITRA],
  8: [trainingPlanModules.MODULE_8_COTCAN, trainingPlanModules.MODULE_8_DAITRA],
};

export const getTpCategoriesInModuleIndexes = (moduleNumbers) => {
  return (moduleNumbers || [])
    .map((n) => TP_CATEGORY_BY_MODULE_INDEX[n])
    .filter((v) => v && v.length > 0)
    .flat();
};

export const isTpCategoriesInModuleIndexes = (tpCategory, moduleNumbers) => {
  return getTpCategoriesInModuleIndexes(moduleNumbers)
    .map(String)
    .includes(String(tpCategory));
};

export const getTrainingPlanCategoryBySchoolYear = (schoolYear) => {
  switch (schoolYear) {
    case 2020: {
      return [
        trainingPlanModules.MODULE_1_COTCAN,
        trainingPlanModules.MODULE_1_DAITRA,
        trainingPlanModules.MODULE_2_COTCAN,
        trainingPlanModules.MODULE_2_DAITRA,
      ];
    }
    case 2021: {
      return [
        trainingPlanModules.MODULE_3_COTCAN,
        trainingPlanModules.MODULE_3_DAITRA,
        trainingPlanModules.MODULE_4_COTCAN,
        trainingPlanModules.MODULE_4_DAITRA,
        trainingPlanModules.MODULE_5_COTCAN,
        trainingPlanModules.MODULE_5_DAITRA,
        trainingPlanModules.MODULE_9_COTCAN,
        trainingPlanModules.MODULE_9_DAITRA,
        trainingPlanModules.MODULE_6_COTCAN,
        trainingPlanModules.MODULE_6_DAITRA,
        trainingPlanModules.MODULE_8_COTCAN,
        trainingPlanModules.MODULE_8_DAITRA,
      ];
    }
    case 2022: {
      return [
        trainingPlanModules.MODULE_7_COTCAN,
        trainingPlanModules.MODULE_7_DAITRA,
      ];
    }
    default: {
      return [];
    }
  }
};

export const getSchoolYearByTrainingPlanCategory = (tpCategory) => {
  const currentSchoolYear = new Date().getFullYear();

  let year = 2020;
  for (year; year <= currentSchoolYear; year += 1) {
    const tpCategories = getTrainingPlanCategoryBySchoolYear(year);

    if (tpCategories.includes(tpCategory)) {
      return year;
    }
  }

  return '';
};

export const getCotCanTpCategoryOfDaiTra = (daitraTpCategory) => {
  const index = TAPHUAN_DAITRA_TRAINING_PLAN_CATEGORIES_IN_ORDER.findIndex(
    (tpCate) => tpCate == daitraTpCategory,
  );

  if (index < 0) {
    return undefined;
  }

  return TAPHUAN_COTCAN_TRAINING_PLAN_CATEGORIES_IN_ORDER[index];
};

export const getDaiTraTpCategoryOfCotCan = (cotcanTpCategory) => {
  const index = TAPHUAN_COTCAN_TRAINING_PLAN_CATEGORIES_IN_ORDER.findIndex(
    (tpCate) => tpCate == cotcanTpCategory,
  );

  if (index < 0) {
    return undefined;
  }

  return TAPHUAN_DAITRA_TRAINING_PLAN_CATEGORIES_IN_ORDER[index];
};

export const isEtepTpCategory = (tpCategory) => {
  tpCategory = parseInt(tpCategory);

  return (
    TAPHUAN_COTCAN_TRAINING_PLAN_CATEGORIES_IN_ORDER.find(
      (tpCat) => tpCat == tpCategory,
    ) ||
    TAPHUAN_DAITRA_TRAINING_PLAN_CATEGORIES_IN_ORDER.find(
      (tpCat) => tpCat == tpCategory,
    )
  );
};

export const isEtepDaitraTpCategory = (tpCategory) => {
  return (
    TAPHUAN_DAITRA_TRAINING_PLAN_CATEGORIES_IN_ORDER.findIndex(
      (tpCate) => tpCate == tpCategory,
    ) !== -1
  );
};

export const isEtepCotCanTpCategory = (tpCategory) => {
  return (
    TAPHUAN_COTCAN_TRAINING_PLAN_CATEGORIES_IN_ORDER.findIndex(
      (tpCate) => tpCate == tpCategory,
    ) !== -1
  );
};

export const getDefaultTrainingPlanCategoryInSearchForm = (userInfo) => {
  let defaultCategory = undefined;

  if (isSGDStaff(userInfo)) {
    defaultCategory = 2;
  }
  if (
    isLTTUStaff(userInfo) ||
    lodashGet(userInfo, 'user_organizations', []).includes(BGD_ORG_IID)
  ) {
    defaultCategory = 1;
  }

  return defaultCategory;
};

export const shouldTrainingPlanUseTaphuanDaiTraLearningFlow = (
  trainingPlan,
) => {
  return isEtepDaitraTpCategory(lodashGet(trainingPlan, 'category'));
};

export const shouldUseTaphuanSmartCotCanLearningFlow = (trainingPlan) => {
  if (
    lodashGet(trainingPlan, 'special_tp_type') ===
    specialTpTypes.SPECIAL_TP_TYPE_TAPHUAN_SMART_COT_CAN
  ) {
    return true;
  }

  return (
    window.isETEP &&
    // TODO: sửa logic này để có thể config động thay vì phải hardcode
    [
      24331318, // "Demo Đợt tập huấn - Mô đun 02 - Giáo viên cốt cán"
      24556398, // "Đợt tập huấn - Mô đun 02 - Giáo viên cốt cán"
      24556402, // "Đợt tập huấn - Mô đun 02 - Cán bộ quản lý cốt cán"
      24825441, // "Đợt tập huấn - Mô đun 03 - Giáo viên cốt cán"
      24866803, // "Đợt tập huấn - Mô đun 03 - Cán bộ quản lý cốt cán"
    ].findIndex((iid) => {
      return lodashGet(trainingPlan, 'iid') == iid;
    }) !== -1
  );
};

export const canSyncUsersFromPreviousModuleToSmartCotCanTp = (trainingPlan) => {
  if (!shouldUseTaphuanSmartCotCanLearningFlow(trainingPlan)) {
    return false;
  }

  // vì không có module nào trước module 1
  if (
    lodashGet(trainingPlan, 'category') == trainingPlanModules.MODULE_1_COTCAN
  ) {
    return false;
  }

  return true;
};

export const canManuallyAddPlannedUsersToSmartCotCanTp = (trainingPlan) => {
  if (!shouldUseTaphuanSmartCotCanLearningFlow(trainingPlan)) {
    return false;
  }

  if (
    lodashGet(
      trainingPlan,
      'taphuan_smart_cot_can_tp.can_add_additional_planned_users',
    )
  ) {
    return true;
  }

  // fallback to old logic, remove this after migrating data
  return (
    shouldUseTaphuanSmartCotCanLearningFlow(trainingPlan) &&
    [etepTps.TP_IID_GVCC_MODULE_2, etepTps.TP_IID_CBQLCC_MODULE_2].findIndex(
      (tpIid) => {
        return lodashGet(trainingPlan, 'iid') == tpIid;
      },
    ) !== -1
  );
};

export const isTaphuanSmartEpV2SupplementaryTrainingPlan = (trainingPlan) => {
  return lodashGet(trainingPlan, 'taphuan_smart_ep_v2_supplementary_tp');
};

export const shouldUseTaphuanSmartEnrolmentPlanV2 = (trainingPlan) => {
  return (
    window.isETEP &&
    !shouldUseTaphuanSmartCotCanLearningFlow(trainingPlan) &&
    !isTaphuanSmartEpV2SupplementaryTrainingPlan(trainingPlan)
  );
};

// whether tp is created in the same org as user's org
export const isTpOrganizationMyOrganization = (user, tp) => {
  const intersected = lodashIntersection(
    lodashGet(user, 'user_organizations'),
    lodashGet(tp, 'organizations'),
  );
  return intersected.length > 0;
};

export const isTpSharedFromParent = (user, tp) => {
  return !!lodashGet(tp, 'is_shared_from_ancestor_organizations');
};

const getModuleKeyToTpCategoryMapping = () => {
  // TODO refactor this
  const moduleIids = window.bdtx_module_iids;

  if (!moduleIids || Object.keys(moduleIids).length !== 4) {
    console.error('Please config bdtx_module_iids correctly (4 modules)');
  }

  const module1Iid = moduleIids.module_1_iid;
  const module2Iid = moduleIids.module_2_iid;
  const module3Iid = moduleIids.module_3_iid;
  const module4Iid = moduleIids.module_4_iid;
  const module5Iid = moduleIids.module_5_iid;
  const module9Iid = moduleIids.module_9_iid;
  const module6Iid = moduleIids.module_6_iid;
  const module7Iid = moduleIids.module_7_iid;
  const module8Iid = moduleIids.module_8_iid;

  // school years for modules of taphuan in bdtx
  const schoolYear2019_2020 = '2019-2020';
  const schoolYear2020_2021 = '2020-2021';

  // module key for tp category of taphuan in bdtx
  const module1CotCanKey =
    module1Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2019_2020;
  const module2CotCanKey =
    module2Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2019_2020;
  const module3CotCanKey =
    module3Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2020_2021;
  const module4CotCanKey =
    module4Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2020_2021;
  const module5CotCanKey =
    module5Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2020_2021;
  const module9CotCanKey =
    module9Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2020_2021;
  const module6CotCanKey =
    module6Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2020_2021;
  const module7CotCanKey =
    module7Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2020_2021;
  const module8CotCanKey =
    module8Iid + '_' + TRAINING_MODEL.COT_CAN + '_' + schoolYear2020_2021;

  const module1DaiTraKey =
    module1Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2019_2020;
  const module2DaiTraKey =
    module2Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2019_2020;
  const module3DaiTraKey =
    module3Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2020_2021;
  const module4DaiTraKey =
    module4Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2020_2021;
  const module5DaiTraKey =
    module5Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2020_2021;
  const module9DaiTraKey =
    module9Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2020_2021;
  const module6DaiTraKey =
    module6Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2020_2021;
  const module7DaiTraKey =
    module7Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2020_2021;
  const module8DaiTraKey =
    module8Iid + '_' + TRAINING_MODEL.DAI_TRA + '_' + schoolYear2020_2021;

  // mapping between modules in bdtx and taphuan tp category
  return {
    [module1CotCanKey]: trainingPlanModules.MODULE_1_COTCAN,
    [module1DaiTraKey]: trainingPlanModules.MODULE_1_DAITRA,

    [module2CotCanKey]: trainingPlanModules.MODULE_2_COTCAN,
    [module2DaiTraKey]: trainingPlanModules.MODULE_2_DAITRA,

    [module3CotCanKey]: trainingPlanModules.MODULE_3_COTCAN,
    [module3DaiTraKey]: trainingPlanModules.MODULE_3_DAITRA,

    [module4CotCanKey]: trainingPlanModules.MODULE_4_COTCAN,
    [module4DaiTraKey]: trainingPlanModules.MODULE_4_DAITRA,

    [module5CotCanKey]: trainingPlanModules.MODULE_5_COTCAN,
    [module5DaiTraKey]: trainingPlanModules.MODULE_5_DAITRA,

    [module9CotCanKey]: trainingPlanModules.MODULE_9_COTCAN,
    [module9DaiTraKey]: trainingPlanModules.MODULE_9_DAITRA,

    [module6CotCanKey]: trainingPlanModules.MODULE_6_COTCAN,
    [module6DaiTraKey]: trainingPlanModules.MODULE_6_DAITRA,

    [module7CotCanKey]: trainingPlanModules.MODULE_7_COTCAN,
    [module7DaiTraKey]: trainingPlanModules.MODULE_7_DAITRA,

    [module8CotCanKey]: trainingPlanModules.MODULE_8_COTCAN,
    [module8DaiTraKey]: trainingPlanModules.MODULE_8_DAITRA,
  };
};

export const createTpCategory = (moduleIid, trainingModel, schoolYear) => {
  if (!moduleIid || !trainingModel || !schoolYear) {
    return null;
  }

  // if this module key points to an existing taphuan tp category then return that tp category
  const predefinedTpCategory = getPredefinedTpCategory(
    moduleIid,
    trainingModel,
  );
  if (predefinedTpCategory) {
    return predefinedTpCategory;
  }

  // else, it should be a new tp category, we create a new int value for this new tp category
  const trainingModelIntValue =
    trainingModel === TRAINING_MODEL.COT_CAN
      ? TRAINING_MODEL_INT_VAL.COT_CAN
      : TRAINING_MODEL_INT_VAL.DAI_TRA;

  // 2020_2021 => 21, keep only 2 last chars of last year
  const schoolYearIntValue = Number.parseInt(schoolYear.slice(-2), 10);

  return Number.parseInt(
    `${moduleIid}${trainingModelIntValue}${schoolYearIntValue}`,
    10,
  );
};

const getPredefinedTpCategory = (moduleIid, trainingModel) => {
  const moduleIids = getPredefinedModuleConfig();

  const module1Iid = moduleIids.module_1_iid;
  const module2Iid = moduleIids.module_2_iid;
  const module3Iid = moduleIids.module_3_iid;
  const module4Iid = moduleIids.module_4_iid;
  const module5Iid = moduleIids.module_5_iid;
  const module9Iid = moduleIids.module_9_iid;
  const module6Iid = moduleIids.module_6_iid;
  const module7Iid = moduleIids.module_7_iid;
  const module8Iid = moduleIids.module_8_iid;

  if (moduleIid == module1Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_1_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_1_DAITRA;
    }
  }

  if (moduleIid == module2Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_2_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_2_DAITRA;
    }
  }

  if (moduleIid == module3Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_3_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_3_DAITRA;
    }
  }

  if (moduleIid == module4Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_4_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_4_DAITRA;
    }
  }

  if (moduleIid == module5Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_5_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_5_DAITRA;
    }
  }

  if (moduleIid == module9Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_9_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_9_DAITRA;
    }
  }

  if (moduleIid == module6Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_6_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_6_DAITRA;
    }
  }

  if (moduleIid == module7Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_7_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_7_DAITRA;
    }
  }

  if (moduleIid == module8Iid) {
    switch (trainingModel) {
      case TRAINING_MODEL.COT_CAN:
        return trainingPlanModules.MODULE_8_COTCAN;
      case TRAINING_MODEL.DAI_TRA:
        return trainingPlanModules.MODULE_8_DAITRA;
    }
  }
};

const getPredefinedModuleConfig = () => {
  const moduleIids = window.bdtx_module_iids;

  const module1Iid = moduleIids.module_1_iid;
  const module2Iid = moduleIids.module_2_iid;
  const module3Iid = moduleIids.module_3_iid;
  const module4Iid = moduleIids.module_4_iid;
  const module5Iid = moduleIids.module_5_iid;
  const module9Iid = moduleIids.module_9_iid;
  const module6Iid = moduleIids.module_6_iid;
  const module7Iid = moduleIids.module_7_iid;
  const module8Iid = moduleIids.module_8_iid;

  if (
    [
      module1Iid,
      module2Iid,
      module3Iid,
      module4Iid,
      module5Iid,
      module9Iid,
      module6Iid,
      module7Iid,
      module8Iid,
    ].some((mdIid) => !mdIid)
  ) {
    throw Error('Please config bdtx_module_iids correctly (4 modules)');
  }

  return moduleIids;
};

export const epCanAddMemberManually = (enrolmentPlan) => {
  const epIid = lodashGet(enrolmentPlan, 'iid');
  const epConfig = lodashGet(window, 'epCanAddMemberManually', []);

  return epConfig.includes(Number(epIid));
};

const getTpCategoriesInOrderThatIncludeTpCategory = (tpCategory) => {
  if (isEtepCotCanTpCategory(tpCategory)) {
    return TAPHUAN_COTCAN_TRAINING_PLAN_CATEGORIES_IN_ORDER;
  }

  if (isEtepDaitraTpCategory(tpCategory)) {
    return TAPHUAN_DAITRA_TRAINING_PLAN_CATEGORIES_IN_ORDER;
  }

  return [];
};

const getTpCategoriesBeforeOrAfter = (
  tpCategory,
  tpCategoriesInOrder,
  direction = 'before',
) => {
  const index = tpCategoriesInOrder.findIndex((c) => c == tpCategory);
  if (index === -1) {
    return [];
  }

  if (direction === 'before') {
    return tpCategoriesInOrder.slice(0, index);
  }

  if (direction === 'after') {
    return tpCategoriesInOrder.slice(index + 1);
  }

  return [];
};

export const getTpCategoriesBefore = (tpCategory) => {
  return getTpCategoriesBeforeOrAfter(
    tpCategory,
    getTpCategoriesInOrderThatIncludeTpCategory(tpCategory),
    'before',
  );
};

export const getTpCategoriesAfter = (tpCategory) => {
  return getTpCategoriesBeforeOrAfter(
    tpCategory,
    getTpCategoriesInOrderThatIncludeTpCategory(tpCategory),
    'after',
  );
};

export const getPreviousTpCategory = (tpCategory) => {
  const tpCategoriesBefore = getTpCategoriesBefore(tpCategory);

  return last(tpCategoriesBefore);
};

export const getNextTpCategory = (tpCategory) => {
  const tpCategoriesAfter = getTpCategoriesAfter(tpCategory);

  return head(tpCategoriesAfter);
};

export const getPreviousCotCanTpCategory = (tpCategory) => {
  if (isEtepDaitraTpCategory(tpCategory)) {
    const previousTpCategory = getPreviousTpCategory(tpCategory);
    return getCotCanTpCategoryOfDaiTra(previousTpCategory);
  }

  if (isEtepCotCanTpCategory(tpCategory)) {
    return getPreviousTpCategory(tpCategory);
  }

  return null;
};

export const getPlannedTrainingDataOfUserInTrainingPlan = (
  user,
  trainingPlanIid,
) => {
  return (lodashGet(user, 'planned_training_data') || [])
    .reverse()
    .find((d) => lodashGet(d, 'training_plan_iid') == trainingPlanIid);
};

export const isMd1CotCanOrDaiTra = (tpCategory) => {
  return [
    trainingPlanModules.MODULE_1_COTCAN,
    trainingPlanModules.MODULE_1_DAITRA,
  ].includes(tpCategory);
};

export const trainingPlanUseCotCanAsTeacher = (trainingPlan) => {
  return lodashGet(trainingPlan, 'use_cot_can_as_teacher');
};

export const trainingPlanUseSchoolGroupLogic = (trainingPlan) => {
  return lodashGet(trainingPlan, 'use_school_group_logic');
};

export const getTrainingPlanCategoryToSyncUsersToSmartCotCanTpCategory = (
  trainingPlanCategory,
) => {
  if (trainingPlanCategory == trainingPlanModules.MODULE_9_COTCAN) {
    return trainingPlanModules.MODULE_4_COTCAN;
  }

  if (
    [
      trainingPlanModules.MODULE_6_COTCAN,
      trainingPlanModules.MODULE_7_COTCAN,
      trainingPlanModules.MODULE_8_COTCAN,
    ]
      .map(String)
      .includes(String(trainingPlanCategory))
  ) {
    return trainingPlanModules.MODULE_9_COTCAN;
  }

  return getPreviousCotCanTpCategory(trainingPlanCategory);
};
